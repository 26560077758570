import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../css/components/Header.module.css';
import { User } from '../sites/Dashboard';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';

interface HeaderProps {
    user: User | null;
}
const logout = () => {
    signOut(auth).then(() => {
        console.log('User signed out successfully');
    }).catch((error) => {
        console.error('Error signing out:', error);
    });
}

const Header: React.FC<HeaderProps> = ({ user }) => {
    return (
        <header className={styles.header}>
            <div className={styles.profileInfo}>
                <h2>Welcome, {user?.displayName}!</h2>
            </div>
            <nav>
                <ul className={styles.navList}>
                    <li>
                        <Link to="/dashboard">Dashboard</Link>
                    </li>
                    <li>
                        <Link to="/subs">Subscriptions</Link>
                    </li>
                    <li>
                        <Link to="/work">Work</Link>
                    </li>
                </ul>
            </nav>
            <div className={styles.profileButtons}>
                <Link to="/settings"><button>Settings</button></Link>
                <button onClick={logout}>Logout</button>
            </div>
        </header>
    );
};

export default Header;
