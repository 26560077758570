import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { collection, doc, deleteDoc, onSnapshot, getDoc, getDocs, query, updateDoc } from 'firebase/firestore';
import styles from '../css/components/SubscriptionList.module.css';

interface Subscription {
	id: string;
	name: string;
	amount: number;
	currency: string;
	period: string;
	startDate: { seconds: number };
	nextDueDate?: { seconds: number };
}
export const getNextChargeDate = (startDate: Date, period: string): Date => {
	const nextChargeDate = new Date(startDate);
	const currentDate = new Date();

	while (nextChargeDate <= currentDate) {
		switch (period) {
			case 'Weekly':
				nextChargeDate.setDate(nextChargeDate.getDate() + 7);
				break;
			case 'Bi-Weekly':
				nextChargeDate.setDate(nextChargeDate.getDate() + 14);
				break;
			case 'Monthly':
				nextChargeDate.setMonth(nextChargeDate.getMonth() + 1);
				break;
			case 'Tri-Monthly':
				nextChargeDate.setMonth(nextChargeDate.getMonth() + 3);
				break;
			case 'Semi-Annually':
				nextChargeDate.setMonth(nextChargeDate.getMonth() + 6);
				break;
			case 'Annually':
				nextChargeDate.setFullYear(nextChargeDate.getFullYear() + 1);
				break;
			default:
				break;
		}
	}

	return nextChargeDate;
};
const SubscriptionList: React.FC = () => {
	const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
	const [conversionRates, setConversionRates] = useState<{ [key: string]: number }>({});
	const [userCurrency, setUserCurrency] = useState<string>('USD');
	const fetchAndUpdateSubscriptions = async () => {
		const user = auth.currentUser;
		if (!user) return;
	
		const subsRef = collection(firestore, `users/${user.uid}/subscriptions`);
		const q = query(subsRef);
		const querySnapshot = await getDocs(q);
	
		const updatedSubscriptions: Subscription[] = [];
		const updatePromises: Promise<void>[] = [];
	
		querySnapshot.forEach((docSnapshot) => {
			const sub = docSnapshot.data() as Subscription;
			sub.id = docSnapshot.id;
	
			if (!sub.nextDueDate) {
				const startDate = new Date(sub.startDate.seconds * 1000);
				const nextDueDate = getNextChargeDate(startDate, sub.period);
				sub.nextDueDate = { seconds: Math.floor(nextDueDate.getTime() / 1000) };
	
				// Update the subscription in Firestore
				const updatePromise = updateDoc(doc(subsRef, sub.id), sub.nextDueDate);
				updatePromises.push(updatePromise);
			}
	
			updatedSubscriptions.push(sub);
		});
	
		// Wait for all updates to complete
		await Promise.all(updatePromises);
	
		setSubscriptions(updatedSubscriptions);
	};
	useEffect(() => {
		const user = auth.currentUser;
		if (user) {
			const fetchUserSettings = async () => {
				const settingsDoc = await getDoc(doc(firestore, `users/${user.uid}/settings/setting`));
				if (settingsDoc.exists()) {
					const settingsData = settingsDoc.data();
					setUserCurrency(settingsData.currency || 'USD');
				}
			};

			fetchUserSettings();

			const subscriptionsCollection = collection(firestore, `users/${user.uid}/subscriptions`);

			const unsubscribe = onSnapshot(subscriptionsCollection, (snapshot) => {
				const subscriptionsData = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data()
				})) as Subscription[];
				console.log("Fetched subscriptions:", subscriptionsData); // Debug log
				setSubscriptions(subscriptionsData);
			});

			return () => unsubscribe();
		} else {
			const unsubscribe = auth.onAuthStateChanged((user) => {
				if (user) {
					const fetchUserSettings = async () => {
						const settingsDoc = await getDoc(doc(firestore, `users/${user.uid}/settings/setting`));
						if (settingsDoc.exists()) {
							const settingsData = settingsDoc.data();
							setUserCurrency(settingsData.currency || 'USD');
						}
					};

					fetchUserSettings();

					const subscriptionsCollection = collection(firestore, `users/${user.uid}/subscriptions`);

					const unsubscribeSubscriptions = onSnapshot(subscriptionsCollection, (snapshot) => {
						const subscriptionsData = snapshot.docs.map((doc) => ({
							id: doc.id,
							...doc.data()
						})) as Subscription[];
						console.log("Fetched subscriptions:", subscriptionsData); // Debug log
						setSubscriptions(subscriptionsData);
					});

					return () => unsubscribeSubscriptions();
				}
			});
			fetchAndUpdateSubscriptions();
			return () => unsubscribe();
		}
	}, []);

	useEffect(() => {
		// Fetch conversion rates (use your preferred API or predefined rates)
		// Example with predefined rates:
		setConversionRates({
			"USD": 1,
			"EUR": 0.85,
			"GBP": 0.75,
			"CAD": 1.37,
			"JPY": 159.79
			// Add other currencies here
		});
	}, []);

	const handleDeleteSubscription = async (subscriptionId: string) => {
		const user = auth.currentUser;
		if (user) {
			const subscriptionDoc = doc(firestore, `users/${user.uid}/subscriptions/${subscriptionId}`);
			await deleteDoc(subscriptionDoc);
			const updatedSubscriptions = subscriptions.filter(sub => sub.id !== subscriptionId);
			setSubscriptions(updatedSubscriptions);
		}
	};

	const convertToUserCurrency = (amount: number, currency: string, userCurrency: string): number => {
		if (!conversionRates[currency] || !conversionRates[userCurrency]) return amount;
		const amountInUserCurrency = (amount / conversionRates[currency]) * conversionRates[userCurrency];
		return amountInUserCurrency;
	};

	

	const getDaysUntilNextCharge = (nextChargeDate: Date): number => {
		const currentDate = new Date();
		const timeDiff = nextChargeDate.getTime() - currentDate.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysDiff;
	};

	const sortedSubscriptions = subscriptions.sort((a, b) => {
		const startDateA = new Date(a.startDate.seconds * 1000);
		const nextChargeDateA = getNextChargeDate(startDateA, a.period);
		const startDateB = new Date(b.startDate.seconds * 1000);
		const nextChargeDateB = getNextChargeDate(startDateB, b.period);

		return nextChargeDateA.getTime() - nextChargeDateB.getTime();
	});

	return (
		<div className={styles.container}>
			<h2>Subscriptions</h2>
			<div className={styles.subscriptionList}>
				{sortedSubscriptions.map((subscription) => {
					const startDate = new Date(subscription.startDate.seconds * 1000);
					const nextChargeDate = getNextChargeDate(startDate, subscription.period);
					const daysUntilNextCharge = getDaysUntilNextCharge(nextChargeDate);

					return (
						<div key={subscription.id} className={styles.subscriptionItem}>
							<button className={styles.deleteButton} onClick={() => handleDeleteSubscription(subscription.id)}>X</button>
							<p>Name: {subscription.name}</p>
							<p>Amount: {convertToUserCurrency(subscription.amount, subscription.currency, userCurrency).toFixed(2)} {userCurrency}</p>
							<p>Period: {subscription.period}</p>
							<p>Start Date: {startDate.toLocaleDateString()}</p>
							<p>Next Charge Date: {nextChargeDate.toLocaleDateString()}</p>
							<p>Next Charge In: {daysUntilNextCharge} days</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SubscriptionList;
