// src/App.tsx
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './sites/Login';
import Register from './sites/Register';
import Dashboard from './sites/Dashboard';
import { auth } from './firebase';
import { User } from './sites/Dashboard';
import Settings from './sites/Settings';
import Subs from './sites/Subs';
import Work from './sites/Work';
import HomePage from './sites/HomePage';

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
		if (user) {
			setUser(user ? { displayName: user.displayName || '', email: user.email || '', uid: user.uid || '' } : null)
		} else {
			setUser(null);
		}
	});
    return () => unsubscribe();
  }, []);

return (
	<Router>
		<Routes>
			<Route path="/settings" element={<Settings />} />
			<Route path="/work" element={<Work user={user} />} />
			<Route path="/dashboard" element={user ? <Dashboard isLoggedIn={true} user={user} /> : <Navigate to="/login" />} />
			<Route path="/subs" element={<Subs/>} />
			<Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
			<Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />
			<Route path="*" element={<HomePage user={user}/>} />
		</Routes>
	</Router>
);
};

export default App;
