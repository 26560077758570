import { fetchUserSettings } from "./Fetch";

export async function calculateTotalWage(
  startDate: Date,
  duration: number // in hours
): Promise<number> {
  const settings = await fetchUserSettings();
  let totalWage = 0;
  
  if (settings) {
    const nightShiftStart = settings.nightShiftStart || "00:00";
    const nightShiftEnd = settings.nightShiftEnd || "00:00";
    const nightShiftWage = settings.nightShiftWage || 0;
    const wage = settings.defaultWage;

    // Helper function to convert "HH:MM" string to Date object aligned with startDate
    const convertToTime = (time: string, baseDate: Date): Date => {
      const [hours, minutes] = time.split(":").map(Number);
      const result = new Date(baseDate);
      result.setDate(baseDate.getDate() + 1);
      result.setHours(hours, minutes, 0, 0);
      return result;
    };

    // Todo: Handle shifts that override default settings
    const nightShiftStartDate = convertToTime(nightShiftStart, startDate);
    const nightShiftEndDate = convertToTime(nightShiftEnd, startDate);

    for (let i = 0; i < duration; i++) {
      const currentHour = new Date(startDate.getTime());
      currentHour.setHours(startDate.getHours() + i);

      let isNightShift = false;

      if (nightShiftWage !== undefined) {
        // Handling shifts that may span across midnight
        if (
          (currentHour >= nightShiftStartDate && currentHour < nightShiftEndDate) ||
          (nightShiftStartDate > nightShiftEndDate &&
            (currentHour >= nightShiftStartDate || currentHour < nightShiftEndDate))
        ) {
          isNightShift = true;
        }
      }

      totalWage += isNightShift ? nightShiftWage : wage;
    }
  }

  return totalWage;
}
