import React, { useState, useEffect } from "react";
import { fetchUpcomingShifts } from "../api/Fetch";
import { Shift } from "../interfaces/Interfaces";
const ShiftCountdown: React.FC = () => {
  const [shift, setShift] = useState(null as Shift | null);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft(): { [key: string]: number } {
    if (shift && shift.startTime && shift.endTime) {
      let difference = +shift.startTime.toDate() - +new Date();
      let timeLeft: { [key: string]: number } = {};
      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      } else {
        difference = +shift.endTime.toDate() - +new Date();
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }
      return timeLeft;
    }
    return {};
  }
  useEffect(() => {
    fetchUpcomingShifts().then((shifts) => {
      if (shifts.length > 0) {
        if (shifts[0].endTime.toDate() > new Date()) {
          setShift(shifts[0]);
        } else {
          setShift(shifts[1]);
        }
      }
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft ?? '').map((interval) => {
    if (timeLeft[interval] < 0) {
      return <span key={interval}>{interval} - </span>;
    }
    if (!timeLeft[interval]) {
      return null;
    }
    return (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      {shift && shift.startTime.toDate() < new Date() ? (
        <p>Time until shift ends:</p>
      ) : (
        <p>Time until next shift:</p>
      )}
      {timerComponents.length ? timerComponents : <span>0</span>}
    </div>
  );
};

export default ShiftCountdown;
