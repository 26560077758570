import { Settings, Shift, Subscription } from '../interfaces/Interfaces';
import { collection, query, where, orderBy, limit, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { getNextChargeDate } from '../components/SubscriptionList';

export async function fetchUpcomingShifts(): Promise<Shift[]> {
    const user = auth.currentUser;
    if (!user) return [];
    const shiftsRef = collection(firestore, `users/${user.uid}/shifts`);
    const now = new Date();
    const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const oneWeekFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
    const q = query(
        shiftsRef,
        where('startTime', '>=', yesterday),
        where('startTime', '<=', oneWeekFromNow),
        orderBy('startTime'),
        limit(3)
    );
    const querySnapshot = await getDocs(q);
    let shifts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as unknown as Shift));
    shifts = shifts.sort((a, b) => a.startTime.seconds - b.startTime.seconds);
    return shifts;
}

export async function fetchUpcomingSubscriptions(limiter?: number): Promise<Subscription[]> {
    const user = auth.currentUser;
    if (!user) return [];
    const subsRef = collection(firestore, `users/${user.uid}/subscriptions`);
    const now = new Date();
    const oneMonthFromNow = new Date(now.getTime() + (30 * 24 * 60 * 60 * 1000));
    const q = query(
        subsRef,
        where('nextDueDate', '>=', now),
        where('nextDueDate', '<=', oneMonthFromNow),
        orderBy('nextDueDate'),
        limit(limiter ?? 3)
    );
    const querySnapshot = await getDocs(q);
    const subscriptions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Subscription));
    return subscriptions;
};

export async function fetchSubscriptions(): Promise<Subscription[]> {
    const user = auth.currentUser;
    if (!user) return [];
    const subsRef = collection(firestore, `users/${user.uid}/subscriptions`);
    const q = query(subsRef);
    const querySnapshot = await getDocs(q);
    const subscriptions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Subscription));
    return subscriptions;
}
export async function fetchSortedSubscriptions(): Promise<Subscription[]> {
    const user = auth.currentUser;

    if (!user) return [];
    const subsRef = collection(firestore, `users/${user.uid}/subscriptions`);
    const q = query(subsRef);
    const querySnapshot = await getDocs(q);
    const subscriptions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Subscription));
    subscriptions.sort((a, b) => a.nextDueDate.seconds - b.nextDueDate.seconds);
    return subscriptions;
}
export async function fetchShifts(): Promise<Shift[]> {
    const user = auth.currentUser;
    if (!user) return [];
    const shiftsRef = collection(firestore, `users/${user.uid}/shifts`);
    const q = query(shiftsRef);
    const querySnapshot = await getDocs(q);
    const shifts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as unknown as Shift));
    console.log("Fetched shifts:", shifts); // Debug log
    return shifts;
}

export async function fetchAndUpdateSubscriptions(): Promise<Subscription[]> {
    const user = auth.currentUser;
    if (!user) return [];

    const subsRef = collection(firestore, `users/${user.uid}/subscriptions`);
    const q = query(subsRef);
    const querySnapshot = await getDocs(q);

    const updatedSubscriptions: Subscription[] = [];
    const updatePromises: Promise<void>[] = [];

    querySnapshot.forEach((docSnapshot) => {
        const sub = docSnapshot.data() as Subscription;
        sub.id = docSnapshot.id;

        if (!sub.nextDueDate) {
            const startDate = new Date(sub.startDate?.seconds ?? 0 * 1000);
            const nextDueDate = getNextChargeDate(startDate, sub.period);
            sub.nextDueDate = { seconds: Math.floor(nextDueDate.getTime() / 1000) };

            // Update the subscription in Firestore
            const updatePromise = updateDoc(doc(subsRef, sub.id), sub.nextDueDate);
            updatePromises.push(updatePromise);
        }

        updatedSubscriptions.push(sub);
    });

    // Wait for all updates to complete
    await Promise.all(updatePromises);

    return updatedSubscriptions;
};

export async function fetchUserSettings(): Promise<Settings | undefined> {
    const user = auth.currentUser;
    if (!user) return undefined;
    const userRef = doc(firestore, `users/${user.uid}/settings/setting`);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
        const data = docSnap.data();
        return data as Settings;
    }
    return undefined;
}