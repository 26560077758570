import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import styles from "../css/components/ShiftForm.module.css";
import { addDoc, collection, doc, getDoc, Timestamp } from "firebase/firestore";
import { Settings, Shift } from "../interfaces/Interfaces";

const ShiftForm: React.FC = () => {
  const [settings, setSettings] = useState<Settings>();
  const [startTime, setStartTime] = useState<string>("");
  const [duration, setDuration] = useState<number>(0);
  const [overrideWage, setOverrideWage] = useState<number>(0);
  const [overrideWageCurrency, setOverrideWageCurrency] = useState<string>("");
  const [nightShiftWage, setNightShiftWage] = useState<number>(0);
  const [nightShiftStart, setNightShiftStart] = useState<Date>(new Date());
  const [overrideSettings, setOverrideSettings] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const currencies = [
    "USD",
    "EUR",
    "GBP",
    "JPY",
    "AUD",
    "CAD",
    "NZD",
  ];
  useEffect(() => {
    const fetchUserSettings = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, `users/${user.uid}/settings/setting`);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const data = docSnap.data(); // Set default values
          setSettings(data as Settings);
          setNightShiftStart(data.nightShiftStart || "00:00");
          setNightShiftWage(data.nightShiftWage || 0);
          setDuration(data.defaultShiftDuration || 0);
          setOverrideWageCurrency(data.currency || "USD");
          setOverrideWage(data.defaultWage || 0); 
        }
      }
    };
    fetchUserSettings();
  }, []);

  const handleAddShift = async () => {
    try {
      setStatus("Adding shift...");
      const user = auth.currentUser;
      let shiftData: Shift;
      if (user) { 
        if (overrideSettings) {
          shiftData = {
            startTime: new Timestamp(new Date(startTime).getTime() / 1000, 0),
            endTime: new Timestamp((new Date(startTime).getTime() + duration * 60 * 60 * 1000) / 1000, 0),
            duration: {
              seconds: duration * 60 * 60,
            },
            overrides: {
              currency: overrideWageCurrency,
              wage: overrideWage,
            },
          };
        } else {      
        shiftData = {
          startTime: new Timestamp(new Date(startTime).getTime() / 1000, 0),
          endTime: new Timestamp((new Date(startTime).getTime() + duration * 60 * 60 * 1000) / 1000, 0),
          hourlyWage: settings?.defaultWage,
          nightShiftWage: settings?.nightShiftWage,
          duration: {
            seconds: duration * 60 * 60,
          },
        };
      }
        await addDoc(
          collection(firestore, `users/${user.uid}/shifts`),
          shiftData
        );
        setNightShiftStart(new Date(settings?.nightShiftStart ?? "00:00"));
        setNightShiftWage(settings?.nightShiftWage || 0);
        setDuration(settings?.defaultShiftDuration || 0);
        setOverrideWageCurrency(settings?.currency || "USD");
        setOverrideWage(settings?.defaultWage || 0);
        setStartTime("");
        setStatus("Shift added successfully");
      }
    } catch (error) {
      setStatus("Error adding shift: " + error);
    }
  };

  return (
    <div>
      <div id="sab" className={styles.container}>
        <h2>Add Shift</h2>
        <p>Shift Start: </p>
        <input
          type="datetime-local"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
        />
        <p>Shift Duration:</p>
        <input
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          placeholder="Duration (hours)"
        />
        <label>
          <input
            type="checkbox"
            checked={overrideSettings}
            onChange={(e) => setOverrideSettings(e.target.checked)}
          />
          Override Settings
        </label>
        {overrideSettings && (
          <>
            <div className={styles.advancedOptions}>
              <p>Wage:</p>
              <div>
                <select
                  id="shiftAddCurrency"
                  value={overrideWageCurrency}
                  onChange={(e) =>
                    setOverrideWageCurrency(e.target.value)
                  }
                >
                  {currencies.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  value={overrideWage}
                  onChange={(e) => setOverrideWage(Number(e.target.value))}
                  placeholder="Wage"
                />
              </div>
            </div>
          </>
        )}
        <button onClick={handleAddShift}>Add Shift</button>
        {status && <p>{status}</p>}
      </div>
      <div id="sac" className={styles.shiftAddedContainer}>
        <h2>Shift Added!</h2>
        <p>Start Time: {startTime}</p>
        <p>End Time: {}</p>
        {overrideSettings && (
          <>
            <p>Override Wage: {overrideWageCurrency.toString() + ' ' + overrideWage}</p>
          </>
        )}
        <br/>
        <button onClick={() => setStatus("")}>Add Another Shift</button>
        <br/>
        <button onClick={() => setStatus("")}>Add Same Shift, Different Day</button>
      </div>
    </div>
  );
};

export default ShiftForm;
