import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import styles from "../css/sites/Settings.module.css";
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithPopup,
  updateEmail,
} from "firebase/auth";
import Header from "../components/Header";

const timezones = [
  "UTC-12:00",
  "UTC-11:00",
  "UTC-10:00",
  "UTC-09:00",
  "UTC-08:00",
  "UTC-07:00",
  "UTC-06:00",
  "UTC-05:00",
  "UTC-04:00",
  "UTC-03:00",
  "UTC-02:00",
  "UTC-01:00",
  "UTC+00:00",
  "UTC+01:00",
  "UTC+02:00",
  "UTC+03:00",
  "UTC+04:00",
  "UTC+05:00",
  "UTC+06:00",
  "UTC+07:00",
  "UTC+08:00",
  "UTC+09:00",
  "UTC+10:00",
  "UTC+11:00",
  "UTC+12:00",
];

const currencies = [
  "USD",
  "EUR",
  "GBP",
  "JPY",
  "AUD",
  "CAD",
  "CHF",
  "CNY",
  "SEK",
  "NZD",
];

const deleteAccount = () => {
  auth.currentUser
    ?.delete()
    .then(() => {
      console.log("User deleted successfully");
    })
    .catch((error) => {
      console.error("Error deleting user:", error);
      if (error.code === "auth/requires-recent-login") {
        signInWithPopup(auth, new GoogleAuthProvider())
          .then(() => {
            auth.currentUser
              ?.delete()
              .then(() => {
                console.log("User deleted successfully");
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          })
          .catch((error) => {
            console.error("Error reauthenticating user:", error);
          });
      }
    });
};

const Settings: React.FC = () => {
  const [userInfo, setUserInfo] = useState<any>(null);

  // User settings, Input fields
  const [editTimezone, setEditTimezone] = useState<string>("");
  const [editCurrency, setEditCurrency] = useState<string>("");
  const [editEmail, setEditEmail] = useState<string>("");
  const [editName, setEditName] = useState<string>("");
  const [editDefaultWage, setEditDefaultWage] = useState<number>(0);
  const [editDefaultShiftDuration, setEditDefaultShiftDuration] = useState<number>(0);
  const [editNightShiftEnabled, setEditNightShiftEnabled] =
    useState<boolean>(false);
  const [editNightShiftStart, setEditNightShiftStart] = useState<string>("");
  const [editNightShiftEnd, setEditNightShiftEnd] = useState<string>("");
  const [editNightShiftWage, setEditNightShiftWage] = useState<number>(0);
  const [editLinkedAccounts, setEditLinkedAccounts] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [user, setUser] = useState<any>(null);

  // Fetch user settings
  useEffect(() => {
    const fetchUserInfo = async () => {
      onAuthStateChanged(auth, (user) => {
        setUser(user);
        if (user) {
          const userRef = doc(firestore, `users/${user.uid}/settings/setting`);
          const unsubscribe = onSnapshot(userRef, (userDoc) => {
            const data = userDoc.data();
            setUserInfo(data);
            setEditTimezone(data?.timezone);
            setEditCurrency(data?.currency);
            setEditEmail(data?.email);
            setEditName(data?.name);
            setEditDefaultWage(data?.defaultWage);
            setEditDefaultShiftDuration(data?.defaultShiftDuration);
            setEditLinkedAccounts(data?.linkedAccounts);
            setEditNightShiftEnabled(data?.nightShiftEnabled);
            setEditNightShiftStart(data?.nightShiftStart);
            setEditNightShiftEnd(data?.nightShiftEnd);
            setEditNightShiftWage(data?.nightShiftWage);
          });
          return () => {
            unsubscribe();
          };
        }
      });
    };
    fetchUserInfo();
  }, []);

  // Save user settings
  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, `users/${user.uid}/settings/setting`);
        await updateDoc(userRef, {
          timezone: editTimezone,
          currency: editCurrency,
          email: editEmail,
          name: editName,
          linkedAccounts: editLinkedAccounts,
          defaultWage: editDefaultWage,
		  defaultShiftDuration: editDefaultShiftDuration,
          nightShiftEnabled: editNightShiftEnabled,
          nightShiftStart: editNightShiftStart,
          nightShiftEnd: editNightShiftEnd,
          nightShiftWage: editNightShiftWage,
        });
        setUserInfo({
          ...userInfo,
          timezone: editTimezone,
          currency: editCurrency,
          email: editEmail,
          name: editName,
          linkedAccounts: editLinkedAccounts,
          defaultWage: editDefaultWage,
		  defaultShiftDuration: editDefaultShiftDuration,
          nightShiftEnabled: editNightShiftEnabled,
          nightShiftStart: editNightShiftStart,
          nightShiftEnd: editNightShiftEnd,
          nightShiftWage: editNightShiftWage,
        });
        if (editEmail !== userInfo.email) {
          updateEmail(user, editEmail);
        }
        setStatus("Settings saved successfully");
      }
    } catch (error) {
      setStatus("Error saving settings: " + error);
    }
  };
  if (!userInfo) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <Header user={user} />
      <div className={styles.container}>
        <h2>Settings</h2>
        <div className={styles.userInfo}>
          <p>
            <strong>Name:</strong> {userInfo.name}
          </p>
          <p>
            <strong>Email:</strong> {userInfo.email}
          </p>
          <p>
            <strong>Timezone:</strong> {userInfo.timezone}
          </p>
          <p>
            <strong>Default Currency:</strong> {userInfo.currency}
          </p>
          <p>
            <strong>Linked Accounts:</strong>{" "}
            {userInfo.linkedAccounts?.join(", ")}
          </p>
        </div>
        <div className={styles.formGroup}>
          <label>Timezone</label>
          <select
            value={editTimezone}
            onChange={(e) => setEditTimezone(e.target.value)}
          >
            {timezones.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </select>
          <label>Default Currency</label>
          <select
            value={editCurrency}
            onChange={(e) => setEditCurrency(e.target.value)}
          >
            {currencies.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </select>
          <label>Account Email Address</label>
          <input
            type="email"
            value={editEmail}
            onChange={(e) => setEditEmail(e.target.value)}
          />
          <label>Name</label>
          <input
            type="text"
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
          />
          <label>Default Wage</label>
          <input
            type="number"
            value={Number(editDefaultWage)}
            onChange={(e) => setEditDefaultWage(Number(e.target.value))}
          />
          <label>Default Shift Duration (hrs)</label>
          <input
            type="number"
            value={Number(editDefaultShiftDuration)}
            onChange={(e) => setEditDefaultShiftDuration(Number(e.target.value))}
          />
          <label>Enable Night Shift</label>
          <input
            type="checkbox"
            checked={editNightShiftEnabled}
            onChange={(e) => setEditNightShiftEnabled(e.target.checked)}
          />
          {editNightShiftEnabled && (
            <div>
              <label>Start Time</label>
              <input
                type="time"
                value={editNightShiftStart}
                onChange={(e) => setEditNightShiftStart(e.target.value)}
              />
              <label>End Time</label>
              <input
                type="time"
                value={editNightShiftEnd}
                onChange={(e) => setEditNightShiftEnd(e.target.value)}
              />
              <label>Night Wage</label>
              <input
                type="number"
                value={Number(editNightShiftWage)}
                onChange={(e) => setEditNightShiftWage(Number(e.target.value))}
              />
            </div>
          )}
          {/*
			<label>Linked Accounts</label>
			<input
			type="text"
			value={editLinkedAccounts.join(', ')}
			onChange={(e) => setEditLinkedAccounts(e.target.value.split(', '))}
			/>
		*/}
        </div>
        <button onClick={handleSave}>Save Settings</button>
        <br />
        <button onClick={deleteAccount}>Delete Account</button>

        {status && <p>{status}</p>}
      </div>
    </div>
  );
};

export default Settings;
