import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import 'firebase/auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, firestore } from '../firebase';
import styles from '../css/sites/Register.module.css'
import { setDoc, collection, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDkTeCHQ9EJFcbmAfZtHhf35p0ZSsu0xc0",
    authDomain: "earnings-tracker-gg140.firebaseapp.com",
    projectId: "earnings-tracker-gg140",
    storageBucket: "earnings-tracker-gg140.appspot.com",
    messagingSenderId: "848675058417",
    appId: "1:848675058417:web:9f8f907912278940d06f50",
    measurementId: "G-CYCRP5FQ2Y"
};
initializeApp(firebaseConfig);

const Register: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user) {
        await setDoc(doc(collection(firestore, 'users/'), user.uid), {
          name,
          email,
        });
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error registering', error);
    }
  };

  return (
    <div className={styles.container}>
      <h2>Register</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleRegister(); }}>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
