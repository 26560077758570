import React, { useEffect, useState } from "react";
import { fetchUpcomingShifts, fetchUpcomingSubscriptions } from "../api/Fetch";
import styles from "../css/sites/Dashboard.module.css";
import Header from "../components/Header";
import { Shift, Subscription } from "../interfaces/Interfaces";
import ShiftCountdown from "../components/ShiftCountdown";

export interface User {
  displayName: string;
  email: string;
  uid: string;
}

interface DashboardProps {
  isLoggedIn: boolean;
  user: User | null;
}

const Dashboard: React.FC<DashboardProps> = ({ isLoggedIn, user }) => {
  const [upcomingShifts, setUpcomingShifts] = useState<Shift[]>([]);
  const [upcomingSubscriptions, setUpcomingSubscriptions] = useState<
    Subscription[]
  >([]);

  useEffect(() => {
    if (isLoggedIn && user) {
      fetchUpcomingShifts().then((shifts) => setUpcomingShifts(shifts));
      fetchUpcomingSubscriptions().then((subs) =>
        setUpcomingSubscriptions(subs as Subscription[])
      );
    }
  }, [isLoggedIn, user]);

  return (
    <div className={styles.container}>
      {isLoggedIn && user ? (
        <>
          <Header user={user} />
          <h1>Dashboard</h1>
          <div className={styles.countdown}>
            {upcomingShifts.length > 0 ? (
              <ShiftCountdown />
            ) : (
              <p>No upcoming shifts in the next month.</p>
            )}
          </div>
          <div className={styles.previewSection}>
            <h2>Upcoming Shifts</h2>
            {upcomingShifts.length > 0 ? (
              upcomingShifts.map((shift) => (
                <div key={shift.id} className={styles.previewItem}>
                  <p>
                    {new Date(
                      shift.startTime.seconds * 1000
                    ).toLocaleString()}{" "}
                  </p>
                  <p>
                    {shift.duration.seconds / 3600} hours{" "}
                    {shift.hourlyWage
                      ? `@ $${shift.hourlyWage.toFixed(2)} ${shift.overrides?.currency ?? ""}`
                      : ""}
                  </p>
                </div>
              ))
            ) : (
              <p>No upcoming shifts in the next month.</p>
            )}
          </div>
          <div className={styles.previewSection}>
            <h2>Upcoming Subscriptions</h2>
            {upcomingSubscriptions.length > 0 ? (
              upcomingSubscriptions.map((sub) => (
                <div key={sub.id} className={styles.previewItem}>
                  <p>{sub.name}</p>
                  <p>
                    {sub.amount} {sub.currency} due on{" "}
                    {new Date(
                      sub.nextDueDate.seconds * 1000
                    ).toLocaleDateString()}
                  </p>
                </div>
              ))
            ) : (
              <p>No upcoming subscriptions in the next month.</p>
            )}
          </div>
        </>
      ) : (
        <div>
          <h2>Please log in or register</h2>
          <button>Login</button>
          <button>Register</button>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
