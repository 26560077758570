import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import styles from "../css/components/SubscriptionForm.module.css";

const currencies = [
    "USD",
    "EUR",
    "GBP",
    "JPY",
    "AUD",
    "CAD",
    "CHF",
    "CNY",
    "SEK",
    "NZD",
];

const periods = [
    "Weekly",
    "Bi-Weekly",
    "Monthly",
    "Tri-Monthly",
    "Semi-Annually",
    "Annually",
];

const SubscriptionForm: React.FC = () => {
    const [name, setName] = useState<string>("");
    const [amount, setAmount] = useState<number>(0.0);
    const [currency, setCurrency] = useState<string>("USD");
    const [period, setPeriod] = useState<string>("Monthly");
    const [startDate, setStartDate] = useState<string>(
        new Date().toISOString().split("T")[0].toString()
    );
    const [status, setStatus] = useState<string>("");
    const [userCurrency, setUserCurrency] = useState<string>("");

    useEffect(() => {
        const fetchUserSettings = async () => {
            const user = auth.currentUser;
            if (user) {
                const settingsDoc = await getDoc(
                    doc(firestore, `users/${user.uid}/settings/setting`)
                );
                if (settingsDoc.exists()) {
                    const settingsData = settingsDoc.data();
                    setCurrency(settingsData.currency || "USD");
                }
            }
        };

        if (auth.currentUser) {
            fetchUserSettings();
        } else {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                if (user) {
                    fetchUserSettings();
                    unsubscribe(); // Unsubscribe after fetching the user info
                }
            });
        }
    }, []);

    const handleAddSubscription = async () => {
        try {
            setStatus("Adding subscription...");
            const user = auth.currentUser;
            if (user) {
                const subscriptionData = {
                    name,
                    amount,
                    currency,
                    period,
                    startDate: new Date(startDate),
                    nextDueDate: new Date(startDate),
                };
                await addDoc(
                    collection(firestore, `users/${user.uid}/subscriptions`),
                    subscriptionData
                );
                setName("");
                setAmount(0);
                setCurrency("USD");
                setPeriod("Monthly");
                setStartDate(new Date().getDate().toString());
                setStatus("Subscription added successfully");
            }
        } catch (error) {
            setStatus("Error adding subscription: " + error);
        }
    };
    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setAmount(Number(value));
        }
    };
    return (
        <div className={styles.container}>
            <h2>Add Subscription</h2>
            <div className={styles.formGroup}>
                <label>Name</label>
                <input
                    type="text"
                    value={name}
                    autoComplete="off"
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Subscription Name"
                />
            </div>
            <div className={styles.formGroup}>
                <label>Amount</label>
                <input
                    type="text"
                    inputMode="decimal"
                    pattern="[0-9]*"
                    value={amount}
                    onChange={handleAmountChange}
                    placeholder="Amount"
                />
            </div>
            <div className={styles.formGroup}>
                <label>Currency</label>
                <select
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                >
                    {currencies.map((currency) => (
                        <option key={currency} value={currency}>
                            {currency}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.formGroup}>
                <label>Period</label>
                <select
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                >
                    {periods.map((period) => (
                        <option key={period} value={period}>
                            {period}
                        </option>
                    ))}
                </select>
            </div>
            <div className={styles.formGroup}>
                <label>Start Date</label>
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    placeholder="Start Date"
                />
            </div>
            <button onClick={handleAddSubscription}>Add Subscription</button>
            {status && <p>{status}</p>}
        </div>
    );
};
export default SubscriptionForm;
