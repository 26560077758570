import { useState } from "react";
import { auth } from "../firebase";
import {
    GoogleAuthProvider,
    getRedirectResult,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import styles from "../css/sites/Login.module.css";
import { initializeApp } from "firebase/app";
import { Link } from "react-router-dom";
// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyDkTeCHQ9EJFcbmAfZtHhf35p0ZSsu0xc0",
    authDomain: "earnings-tracker-gg140.firebaseapp.com",
    projectId: "earnings-tracker-gg140",
    storageBucket: "earnings-tracker-gg140.appspot.com",
    messagingSenderId: "848675058417",
    appId: "1:848675058417:web:9f8f907912278940d06f50",
    measurementId: "G-CYCRP5FQ2Y",
};
initializeApp(firebaseConfig);
const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [status, setStatus] = useState("");

    const handleSubmit = async () =>
        await signInWithEmailAndPassword(auth, username, password).catch(
            (error) => {
                setStatus(error.message);
            }
        );
    const handleRegisterGoogle = async () => {
        setStatus("Logging in with Google...");
        const provider = new GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        await signInWithPopup(auth, provider).catch((error) => {
            setStatus("Error registering with Google:" + error);
        });

        const result = await getRedirectResult(auth);
        if (result?.user) {
            setStatus("User signed in with Google");
        }
    };
    return (
        <div className={styles.container}>
            <h1>Finance Fortress</h1>
            <br />
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input
                        type="text"
                        value={username}
                        autoComplete="email"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    Password:
                    <input
                        type="password"
                        value={password}
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </label>
                <br />
                <button className="center" onClick={handleSubmit}>
                    Login
                </button>
                <p>
                    Don't have an account? <Link to="/register">Register</Link>
                </p>

                <button type="button" onClick={handleRegisterGoogle}>
                    Login with Google
                </button>
            </form>
            {status && <p>{status}</p>}
        </div>
    );
};

export default Login;
