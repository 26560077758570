// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyDkTeCHQ9EJFcbmAfZtHhf35p0ZSsu0xc0",
	authDomain: "earnings-tracker-gg140.firebaseapp.com",
	projectId: "earnings-tracker-gg140",
	storageBucket: "earnings-tracker-gg140.appspot.com",
	messagingSenderId: "848675058417",
	appId: "1:848675058417:web:9f8f907912278940d06f50",
	measurementId: "G-CYCRP5FQ2Y"
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
