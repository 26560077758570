import { User } from "./Dashboard";
import styles from "../css/sites/Work.module.css";
import Header from "../components/Header";
import ShiftForm from "../components/ShiftForm";
import ShiftList from "../components/ShiftList";
import Popup from "reactjs-popup";
interface WorkProps {
    user: User | null;
  }
const Work: React.FC<WorkProps> = ({user}) => {
    document.title = "FF | Work";
    return (
        <>
            <Header user={user} />
            <div id='shiftSection' className={styles.container}>
                <h1>Work</h1>
                <Popup className={styles.popup} trigger={<button>Add Shift</button>} position="top center" modal >
                    <ShiftForm />
                </Popup>
                <ShiftList />
            </div>
        </>
    );
}

export default Work;