import React, { useEffect, useState } from "react";
import styles from "../css/components/ShiftList.module.css";
import { fetchShifts, fetchUserSettings } from "../api/Fetch";
import { Shift } from "../interfaces/Interfaces";
import { deleteDoc, doc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { calculateTotalWage } from "../api/Util";
import "chart.js/auto";
import Chart from "chart.js/auto";

const ShiftList: React.FC = () => {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const [shiftTotalGross, setShiftTotalGross] = useState<number[]>([]);
  const [defaultWage, setDefaultWage] = useState<number>(0);
  const [defaultCurrency, setDefaultCurrency] = useState<string | undefined>("");
  const [weekMap, setWeekMap] = useState<Map<string, Shift[]>>(new Map());

  useEffect(() => {
    let chart: unknown;
    const chartarea = document.getElementById(
      "grossChart"
    ) as HTMLCanvasElement;
    if (chart && chart instanceof Chart) chart.destroy();
    if (chartarea) {
      const labels = shifts.map((shift) =>
        shift.startTime.toDate().toLocaleDateString()
      );
      const data = shiftTotalGross;
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#FF6633",
        "#FF33FF",
        "#33FF33",
        "#33CCFF",
      ];
      chart = new Chart(chartarea, {
        type: "line",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: colors,
            },
          ],
        },
      });
    }
  });

  useEffect(() => {
    // Fetch user settings
    fetchUserSettings().then((settings) => {
      if (settings) {
        setDefaultWage(settings.defaultWage);
        setDefaultCurrency(settings.currency);
      }
    });
    // Fetch shifts from Firestore
    const refreshShifts = () => {
      fetchShifts().then((shifts) => {
        // Sort shifts by start time in descending order
        shifts.sort((a, b) => b.startTime.seconds - a.startTime.seconds);
        setShifts(shifts);
        shifts.forEach((shift) => {
          setShiftTotalGross([]);
          calculateTotalWage(
            shift.startTime.toDate(),
            shift.duration.seconds / 3600
          ).then((totalWage) => {
            setShiftTotalGross((prev) => [...prev, totalWage]);
          });
        });
        // Group shifts by week
        const weekMap = new Map<string, Shift[]>();
        shifts.forEach((shift) => {
          const date = shift.startTime.toDate();
          const weekStart = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - date.getDay()
          );
          const weekEnd = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - date.getDay() + 6
          );
          const key = `${weekStart.toDateString()} - ${weekEnd.toDateString()}`;
          if (!weekMap.has(key)) {
            weekMap.set(key, []);
          }
          weekMap.get(key)?.push(shift);
          setWeekMap(weekMap);
        });
      });
    };
    refreshShifts();
  }, []);

  async function handleDeleteShift(id: string | undefined): Promise<void> {
    if (!id) return;
    const user = auth.currentUser;
    if (user) {
      const shiftDoc = doc(firestore, `users/${user.uid}/shifts/${id}`);
      await deleteDoc(shiftDoc);
      const updatedShifts = shifts.filter((sub) => sub.id !== id);
      setShifts(updatedShifts);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.shiftList}>
        <h2>Shifts</h2>
        {shifts.length > 0 ? (
          shifts.map((shift, i) => (
            <div key={shift.id} className={styles.shiftItem}>
              <button
                className={styles.deleteButton}
                onClick={() => handleDeleteShift(shift.id)}
              >
                X
              </button>
              <p>
                {shift.startTime.toDate().toLocaleDateString()} from{" "}
                {shift.startTime.toDate().toLocaleTimeString()} to{" "}
                {shift.endTime.toDate().toLocaleTimeString()}
              </p>
              <div>
                <p>
                  {shift.nightShiftWage ? "Day:" : ""}{" "}
                  {shift.hourlyWage ?? defaultWage}{" "}
                  {shift.overrides?.currency || defaultCurrency} per hour
                </p>
                {shift.nightShiftWage && (
                  <p>
                    Night: {shift.nightShiftWage}{" "}
                    {shift.overrides?.currency || defaultCurrency} per hour
                  </p>
                )}
              </div>

              <p>
                Gross: {shift.overrides?.currency || defaultCurrency}{" $"}
                {shiftTotalGross[i]?.toFixed(2)}
              </p>
            </div>
          ))
        ) : (
          <p>No shifts found.</p>
        )}
      </div>
      {weekMap.size > 0 && (
        <div className={styles.weeklyStats}>
          <h2>Weekly Stats</h2>
          {Array.from(weekMap).map(([week, shifts]) => (
            <div key={week} className={styles.weeklyStat}>
              <h3>{week}</h3>
              <p>
                Total Gross:{" " + defaultCurrency + " $"}
                {shifts.reduce((a, b, i) => a + shiftTotalGross[i], 0).toFixed(
                  2
                )}
              </p>
            </div>
          ))}
          <p>
          Total Gross: {shiftTotalGross.reduce((a, b) => a + b, 0).toFixed(2)}
          </p>
          </div>
      )}
    </div>
  );
};

export default ShiftList;
