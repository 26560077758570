import { auth } from '../firebase';
import styles from '../css/sites/Subs.module.css';
import SubscriptionForm from '../components/SubscriptionForm';
import SubscriptionList from '../components/SubscriptionList';
import { User } from './Dashboard';
import { fetchSortedSubscriptions } from '../api/Fetch';
import Header from '../components/Header';
import Popup from 'reactjs-popup';
import Chart from 'chart.js/auto';
import { useEffect, useState } from 'react';

const Subs: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    
    useEffect(() => {
        let chart: unknown;
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user ? { displayName: user.displayName || '', email: user.email || '', uid: user.uid || '' } : null)
                makeChart();
            } else {
                setUser(null);
            }
        });
        const makeChart = () => {
            fetchSortedSubscriptions().then((subs) => {
                const monthlySubs = subs.filter((sub) => sub.period === 'Monthly');

                const ctx = document.getElementById('myChart') as HTMLCanvasElement;
                ctx.innerHTML = '';
                if (ctx) {
                    const labels = monthlySubs.map((sub) => sub.name);
                    const data = monthlySubs.map((sub) => sub.amount);
                    const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FF6633', '#FF33FF', '#33FF33', '#33CCFF'];
                    if (chart && chart instanceof Chart) {
                        chart.destroy();
                    }
                    chart = new Chart(ctx, {
                        type: 'doughnut',
                        data: {
                            labels,
                            datasets: [
                                {
                                    data,
                                    backgroundColor: colors,
                                },
                            ],
                        },
                    });
                }
            });
        };
        return () => unsubscribe();
    }, []);

    return (
        <>
            <Header user={user} />
            <div className={styles.container}>
                <h1>Subscriptions</h1>
                <Popup className={styles.popup} trigger={<div className={styles.buttons}><button>Add Subsciption</button></div>} position="top center" modal >
                        <SubscriptionForm />
                </Popup>
                <div className={styles.subStats}>
                    <div className={styles.chart}>
                        <canvas id="myChart"></canvas>
                    </div>
                </div>
                <div className={styles.sublist}>
                    <SubscriptionList />
                </div>   
            </div>
        </>
    );
};

export default Subs;