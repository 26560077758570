import { User } from "./Dashboard";
import styles from "../css/sites/HomePage.module.css";
import { Link } from "react-router-dom";
interface HomePageProps {
    user: User | null;
}
const Work: React.FC<HomePageProps> = () => {
    document.title = "FF | Home";
    return (
        <>
            <header className={styles.hpHeader}>
                <h1>Finance Fortress</h1>
                <div className={styles.buttons}>
                    <Link to="/login"><button>Login</button></Link>
                    <Link to="/register"><button>Register</button></Link>
                </div>
            </header>
            <main className={styles.main}>
                <h1>The Finance Manager that doesn't exist</h1>
                <Link to="/login"><button>Get Started</button></Link>
            </main>
        </>
    );
}

export default Work;